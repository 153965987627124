import React from 'react'
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Container from './section'
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid'

export const Table = ({ title, rows }) => (
  <Grid container>
    <Grid item xs={12} sm={6} md={8}>
      <Typography variant="h4">{title}</Typography>
      <TableContainer>
        <MuiTable>
          {/* <TableHead>
            <TableRow>
              <TableCell>STAT</TableCell>
              <TableCell align="right">VALUE</TableCell>
            </TableRow>
          </TableHead> */}
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.label}>
                <TableCell>{row.label}</TableCell>
                <TableCell align="right">{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Grid>
  </Grid>
)

export default ({ title, rows, maxWidth }) => {
  return (
    <Container maxWidth={maxWidth}>
      <Table title={title} rows={rows} />
    </Container>
  )
}