import React from 'react'
import Section from './section'
// import { Typography } from '@material-ui/core'
import Img from "gatsby-image"
// import { Link } from 'gatsby'
import styled from '@emotion/styled'
// import { css } from '@emotion/core'
// import breakpoints from '../utils/breakpoints'

const SectionImage = styled(Img)`
  /* max-width: ${props => props.theme.spacing(100)}px; */
  /* margin: 0 auto; */
`

export const Image = ({ image }) => (
  <SectionImage fluid={image.childImageSharp.fluid} />
)

export default ({ image, maxWidth }) => {
  return (
    <Section maxWidth={maxWidth}>
      <Image image={image} />
    </Section>
  )
}
