import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { SimpleHeader } from '../components/section-header'
import TableSection from '../components/section-table'
import TextWithImageSection from '../components/section-text-image'
import ImageSection from '../components/section-image'
import TagsSection from '../components/section-tags'
import Section from '../components/section'
import Content from '../components/html-content'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import { Link } from "gatsby"
import Seo from '../components/seo'

const backButton = (
  <Button component={Link} to={`/case-study`} startIcon={<ArrowBackIcon />}>
    Back to Case Studies
  </Button>
)

export default ({ data }) => {
  const caseStudy = data.markdownRemark

  return (
    <Layout>
      <Seo
        title={caseStudy.frontmatter.title}
        description={caseStudy.frontmatter.description}
        article
        image={caseStudy.frontmatter.teaserImage.childImageSharp.fluid.src}
      />
      <SimpleHeader
        eyebrow={caseStudy.frontmatter.description}
        title={caseStudy.frontmatter.title}
      />
      <TagsSection backButton={backButton} tags={caseStudy.frontmatter.tags} maxWidth="md" />
      <ImageSection image={caseStudy.frontmatter.teaserImage} maxWidth="md" />
      <TableSection title="Key Facts" rows={caseStudy.frontmatter.stats} maxWidth="md" />
      <TextWithImageSection {...caseStudy.frontmatter.textWithImage} maxWidth="md" />
      <Section maxWidth="md">
        <Content html={caseStudy.html} />
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query CaseStudyQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        stats {
          label
          value
        }
        textWithImage {
          title
          subtitle
          text
          image {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          link {
            title
            url
          }
        }
        teaserImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        tags
      }
      html
    }
  }
`