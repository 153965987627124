import React from 'react'
import Section from './section'
import { Typography } from '@material-ui/core'
import Img from "gatsby-image"
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import breakpoints from '../utils/breakpoints'
import Grid from '@material-ui/core/Grid'

const Title = styled(Typography)`
  text-align: center;

  ${breakpoints.sm} {
    text-align: left;
  }
`

export default ({ title, subtitle, text, image, link, maxWidth }) => (
  <Section maxWidth={maxWidth}>
    <Grid container direction='row-reverse' spacing={6}>
      <Grid item xs={12} sm={6} md={4}>
        <Img fluid={image.childImageSharp.fluid} />
      </Grid>
      <Grid item xs={12} sm={6} md={8}>
        <Title variant='h4'>{title}</Title>
        <Title display="block" variant='subtitle1'>{subtitle}</Title>
        <Typography variant='h6'>{text}</Typography>    
        <Typography component={Link} to={link.url}>{link.title}</Typography>
      </Grid>
    </Grid>
  </Section>
)